import React from 'react';
import { connect } from 'react-redux';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import { ThemeProvider } from '../../theme/styled-components';
import { getTheme } from '../../theme/theme';
import { appSettingsSelector } from '../DomainResolver/selectors';

const mapStateToProps = (state: RootState) => {
  return {
    appSettings: appSettingsSelector(state),
  };
};

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = ReduxConnectProps & IMapStateToProps & RouteProps;

class Themer extends React.PureComponent<Props> {
  // static async getInitialProps(props: Props & SSRProps) {
  //   const { dispatch } = props;
  //   await Promise.all([dispatch(loadPpoTheme.action())]);
  // }

  // componentDidMount() {
  //   Themer.getInitialProps(this.props);
  // }

  render() {
    const {
      // appSettings,
      children,
    } = this.props;
    return (
      <ThemeProvider
        theme={
          // getTheme(appSettings.theme || {})
          getTheme({})
        }
      >
        <>{children}</>
      </ThemeProvider>
    );
  }
}

export default connect(mapStateToProps)(Themer);
