import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType, RootState } from '../../configureStore';
import { NormalizedEntities } from '../../library/App';
import IAppSettings from '../../library/AppSettings';
import NotFoundError from '../../NotFoundError';
import normalizeEntities from '../../utilities/normalizeEntities';

const create = actionCreatorFactory('DOMAIN_RESOLVER');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

const splitter = process.env.REACT_APP_FIXED_DOMAIN_NAME as string;
if (!splitter) {
  throw new Error('REACT_APP_FIXED_DOMAIN_NAME not set');
}

export const loadAppSettings = createAsync<
  {
    domain: string;
  },
  IAppSettings
>('GET_SETTINGS', async ({ domain }, dispatch, getState, { DomainsApi }) => {
  try {
    let [appSpace] = domain.split(`.${splitter}`);

    let baseUri = '';

    if (appSpace) {
      const port = process.env.REACT_APP_PORT;
      const protocol = process.env.REACT_APP_PROTOCOL;
      baseUri = `${protocol}://${appSpace}.${splitter}${
        port ? `:${port}` : ''
      }`;
    } else {
      const resolvedDomain = await DomainsApi.getDomainInfo(domain);
      appSpace = resolvedDomain.ppo;
      baseUri = `https://${resolvedDomain._id}`.replace(/\/$/, '');
    }

    return {
      appSpace,
      baseUri,
    } as IAppSettings;
  } catch (e) {
    throw new NotFoundError(e);
  }
});

export const loadPpo = createAsync<
  {
    appSpace: string;
  },
  NormalizedEntities<'PPO'>
>('GET_PPO', async (parameters, dispatch, getState, { CoreApi }) => {
  const ppo = await CoreApi.organizationPPOProfile(parameters.appSpace);
  return normalizeEntities('PPO', [ppo]);
});

export const loadPOInvoiceProfile = createAsync<
  {
    id: string;
  },
  NormalizedEntities<'PO'>
>('GET_PO_INVOICE_PROFILE', async ({ id }, dispatch, getState, { CoreApi }) => {
  const invoiceProfile = await CoreApi.organizationInvoiceProfile(id);
  return normalizeEntities('PO', [invoiceProfile]);
});
