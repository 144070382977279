import * as React from 'react';
import { WrappedFieldProps } from 'redux-form';
import { FileObjectDefinition } from './definitions';
import FileInput from './index';

const FileInputRedux = (
  props: WrappedFieldProps & {
    onFileClick: (file: FileObjectDefinition) => void;
  },
) => {
  const {
    input,
    meta: { error, touched },
    ...restProps
  } = props;
  const value = input.value || [];
  return (
    <FileInput
      {...input}
      {...restProps}
      value={value}
      error={error && touched}
      onFileClick={props.onFileClick}
    />
  );
};

export default FileInputRedux;
