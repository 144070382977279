import FormField from '@sportnet/ui/lib/FormField/redux-form';
import Label from '@sportnet/ui/lib/Label/Label';
import { em } from 'polished';
import * as React from 'react';
import { Field } from 'redux-form';
import { IFormSegmentElement } from '../../../library/Form';
import required from '../../../utilities/required';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  disabled: boolean;
}

class CheckboxGroupWithInputFormElement extends React.PureComponent<OwnProps> {
  state = {
    isOther: false,
  };
  render() {
    const { data, name, disabled } = this.props;
    const isRequired = (data.validations || []).find(
      validation => validation.type === 'required',
    );

    return (
      <>
        {data.label && (
          <Label
            {...(!!isRequired && {
              required: true,
            })}
          >
            {data.label}
          </Label>
        )}
        <div style={{ paddingTop: em(10) }}>
          {(data.options || []).map((option, idx) => (
            <Field
              key={option}
              disabled={disabled}
              name={`${name}[${option}]`}
              component={FormField}
              label={option}
              type="checkbox"
            />
          ))}
          <Field
            name={`${name}[Iné]`}
            disabled={disabled}
            component={FormField}
            label="Iné"
            type="checkbox"
            onChange={(i: any) => {
              this.setState({
                isOther: i.currentTarget.checked,
              });
              return i;
            }}
          />
          {this.state.isOther && (
            <Field
              required
              readOnly={disabled}
              validate={[required]}
              name={`${name}[Iné-Text]`}
              component={FormField}
            />
          )}
        </div>
      </>
    );
  }
}

export default CheckboxGroupWithInputFormElement;
