import Col, { Row } from '@sportnet/ui/lib/Grid';
import { Loader } from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import styled, { withTheme } from 'styled-components';
import CoreApi from '../../../api/CoreApi';
import { IFormSegmentElement } from '../../../library/Form';
import { IThemeProps } from '../../../theme/styled-components';
import __ from '../../../utilities/__';
import CheckboxFormElement from '../../FormElements/CheckboxFormElement';
import SelectFormElement from '../../FormElements/SelectFormElement';
import TextInputFormElement from '../../FormElements/TextInputFormElement';
import legalForms from './legalForms';
interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  dispatchChange: (field: string, value: any) => void;
  disabled: boolean;
}

const Wrapper = styled('div')`
  position: relative;
`;

const LoaderWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class CompanyInfoGroupFormElement extends React.PureComponent<
  OwnProps & IThemeProps
> {
  state = {
    companyIsFetching: false,
  };
  handleICOChange = async (e: React.FocusEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;
    if (value && !this.props.disabled) {
      this.setState({
        companyIsFetching: true,
      });
      try {
        const {
          organization: { full_name, dic, business_id, vat_nr, addresses = [] },
        } = (await CoreApi.getBusinessInfo(value)) as {
          organization: {
            full_name: string;
            dic: string;
            vat_nr: string | null;
            business_id: string;
            addresses: Array<{
              city: string;
              country: string;
              number: string;
              street: string;
              zip: string;
            }>;
          };
        };
        const address = addresses[0];
        this.props.dispatchChange(this.props.name, {
          companyName: full_name,
          ico: business_id,
          dic,
          companyAddress: address
            ? `${address.street} ${address.number}, ${address.zip} ${address.city}`
            : '',
          vatPayer: !!vat_nr,
        });
      } catch (e) {
        alert(__('Spoločnosť nebolo možné podľa IČO vyhľadať.'));
      } finally {
        this.setState({
          companyIsFetching: false,
        });
      }
    }
  };

  trimValue = (value: string) => String(value).replace(/\s/g, '');

  render() {
    const { name, theme, disabled } = this.props;
    return (
      <Wrapper>
        {this.state.companyIsFetching && (
          <LoaderWrapper>
            <Loader size="xl" theme={theme} />
          </LoaderWrapper>
        )}
        <Row>
          <Col xs={12} s={6}>
            <TextInputFormElement
              disabled={disabled}
              name={`${name}.companyName`}
              data={{
                _id: 'companyName',
                label: __('Názov'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={12} s={6}>
            <TextInputFormElement
              disabled={disabled}
              name={`${name}.companyAddress`}
              data={{
                _id: 'companyAddress',
                label: __('Sídlo'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={12} s={6}>
            <SelectFormElement
              disabled={disabled}
              name={`${name}.legalForm`}
              data={{
                _id: 'legalForm',
                label: __('Právna forma'),
                validations: [{ type: 'required' }],
                type: 'Select',
                options: legalForms,
              }}
            />
          </Col>
          <Col xs={12} s={3}>
            <TextInputFormElement
              disabled={disabled}
              name={`${name}.ico`}
              onBlur={this.handleICOChange}
              parse={this.trimValue}
              data={{
                _id: 'ico',
                label: __('IČO'),
                validations: [
                  { type: 'required' },
                  { type: 'minLength', payload: { minLength: 8 } },
                  { type: 'maxLength', payload: { maxLength: 8 } },
                ],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={12} s={3}>
            <TextInputFormElement
              disabled={disabled}
              name={`${name}.dic`}
              data={{
                _id: 'dic',
                label: __('DIČ'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={12}>
            <CheckboxFormElement
              disabled={disabled}
              name={`${name}.vatPayer`}
              data={{
                _id: 'vatPayer',
                label: __('Platca DPH'),
                type: 'Checkbox',
              }}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default withTheme(CompanyInfoGroupFormElement);
