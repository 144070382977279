import __ from './__';

export default (email: string) => {
  const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const test = re.test(String(email).toLowerCase());
  if (!test) {
    return __('Zadajte prosím platnú e-mailovú adresu');
  }
  return null;
};
