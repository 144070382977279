/* tslint:disable */
import 'isomorphic-fetch';

export type ThenArg<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T;

type QueryParameters = {
  [key: string]:
    | string
    | number
    | string[]
    | number[]
    | boolean
    | boolean[]
    | undefined;
};

export type Error = {
  statusCode: number;

  name: string;

  description: string | null;

  payload?: object | null;

  userinfo?: string;
};
export type Domain = {
  _id: string;

  ppo: string;

  appId: string;

  aliases?: Array<string>;

  payload?: {};

  cert: {
    type: 'letsencrypt';
  };

  status: 'pending' | 'updated' | 'live' | 'error';

  error?: string;
};
export type DomainCreate = {
  _id: string;

  aliases?: Array<string>;

  payload?: {};

  cert?: {
    type: 'letsencrypt';
  };
};
export type DomainUpdate = {
  aliases?: Array<string>;

  payload?: {};
};

class ApiError {
  public message: string;
  public details: Error | null = null;

  constructor(message: string) {
    this.message = message;
  }
}

/**
 *
 * @class DomainsApi
 * @param {(string)} [domainOrOptions] - The project domain.
 */
class DomainsApi {
  protected baseUrl: string = 'https://domains.sportnet.online/api/v1';
  protected token: string = '';

  serializeQueryParams(parameters: QueryParameters) {
    return Object.keys(parameters)
      .reduce((acc: string[], p) => {
        const param = parameters[p];
        if (typeof param === 'undefined' || param === '') {
          return acc;
        }
        return [
          ...acc,
          `${encodeURIComponent(p)}=${encodeURIComponent(
            String(parameters[p]),
          )}`,
        ];
      }, [])
      .join('&');
  }

  protected transformParameter(value: any, transformOperation?: string) {
    switch (transformOperation) {
      case 'joinUsingPipes':
        return Array.isArray(value) ? value.join('|') : value;
      default:
        return value;
    }
  }

  public setBaseUrl(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  public setToken(token: string) {
    this.token = token;
  }

  protected appendAuthHeaders(headerParams: Headers) {
    const headers = new Headers(headerParams);
    if (this.token) {
      headers.append('Authorization', `Bearer ${this.token}`);
    }
    return headers;
  }

  private async request(
    method: string,
    url: string,
    body: any,
    headers: Headers = new Headers(),
    queryParameters: QueryParameters = {},
  ) {
    const queryParams =
      queryParameters && Object.keys(queryParameters).length
        ? this.serializeQueryParams(queryParameters)
        : null;
    const urlWithParams = url + (queryParams ? '?' + queryParams : '');

    if (headers.get('Content-Type') === 'multipart/form-data') {
      const form = new FormData();
      for (let k in body) {
        form.append(k, body[k]);
      }
      body = form;
    } else if (
      headers.get('Content-Type') === 'application/json' &&
      body &&
      Object.keys(body).length > 0
    ) {
      body = JSON.stringify(body);
    } else {
      body = undefined;
    }

    if (headers.get('Content-Type') === 'multipart/form-data') {
      headers.delete('Content-Type');
    }

    const response = await fetch(urlWithParams, { method, headers, body });
    if (response.ok) {
      return response.json();
    } else {
      const err = new ApiError(response.statusText);
      err.details = await response.json();
      throw err;
    }
  }

  /**
   * List domains registered for PPO and appId
   * @method
   * @name DomainsApi#appListDomains
   */
  appListDomains(
    appId: string,
    appSpace: string,
    parameters: {} = {},
  ): Promise<{
    domains?: Array<Domain>;
  }> {
    let path = '/app/{appId}/{appSpace}/domains';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Create new domain for PPO
   * @method
   * @name DomainsApi#appCreateDomain
   */
  appCreateDomain(
    appId: string,
    appSpace: string,
    parameters: {},
    body: DomainCreate,
  ): Promise<Domain> {
    let path = '/app/{appId}/{appSpace}/domains';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    return this.request(
      'POST',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Get domain detail
   * @method
   * @name DomainsApi#appGetDomain
   */
  appGetDomain(
    appId: string,
    appSpace: string,
    domain: string,
    parameters: {} = {},
  ): Promise<Domain> {
    let path = '/app/{appId}/{appSpace}/domains/{domain}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{domain}', domain.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Update domain
   * @method
   * @name DomainsApi#appUpdateDomain
   */
  appUpdateDomain(
    appId: string,
    appSpace: string,
    domain: string,
    parameters: {},
    body: DomainUpdate,
  ): Promise<Domain> {
    let path = '/app/{appId}/{appSpace}/domains/{domain}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{domain}', domain.toString());

    return this.request(
      'PUT',
      `${this.baseUrl}${path}`,
      body,
      headers,
      queryParameters,
    );
  }

  /**
   * Delete domain
   * @method
   * @name DomainsApi#appDeleteDomain
   */
  appDeleteDomain(
    appId: string,
    appSpace: string,
    domain: string,
    parameters: {} = {},
  ): Promise<{}> {
    let path = '/app/{appId}/{appSpace}/domains/{domain}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers = this.appendAuthHeaders(headers);
    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{appId}', appId.toString());

    path = path.replace('{appSpace}', appSpace.toString());

    path = path.replace('{domain}', domain.toString());

    return this.request(
      'DELETE',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Domain detail
   * @method
   * @name DomainsApi#getDomainInfo
   */
  getDomainInfo(domain: string, parameters: {} = {}): Promise<Domain> {
    let path = '/domain/{domain}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{domain}', domain.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }

  /**
   * Domain DNS check
   * @method
   * @name DomainsApi#dnscheckDomain
   */
  dnscheckDomain(
    domain: string,
    parameters: {} = {},
  ): Promise<{
    dnscheck?: boolean;
  }> {
    let path = '/dnscheck/{domain}';
    let headers: Headers = new Headers();
    let queryParameters: QueryParameters = {};

    headers.append('Accept', 'application/json');
    headers.append('Content-Type', 'application/json');

    path = path.replace('{domain}', domain.toString());

    return this.request(
      'GET',
      `${this.baseUrl}${path}`,
      {},
      headers,
      queryParameters,
    );
  }
}

export default new DomainsApi();

