// styled-components.ts
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';
import { ITheme } from './theme';

const {
  default: styled,
  css,
  keyframes,
  withTheme,
  ThemeProvider,
} = styledComponents as ThemedStyledComponentsModule<ITheme>;

export { css, keyframes, ThemeProvider, withTheme };
export default styled;

export interface IThemeProps {
  theme: ITheme;
}
