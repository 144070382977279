import { ACCESS_TOKEN_COOKIE_NAME } from '@sportnet/ui/lib/Authorization';
import Image from '@sportnet/ui/lib/Image';
import { mb } from '@sportnet/ui/lib/Themes/utilities';
import Avatar from '@sportnet/ui/lib/TitlePanel/User';
import Cookies from 'js-cookie';
import { readableColor, rem } from 'polished';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router';
import { compose } from 'redux';
import { getProp } from 'sportnet-utilities';
import { CenteredContentWrapper } from '../../components/Layout';
import MaxWidthBox from '../../components/MaxWidthBox';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import styled, { css } from '../../theme/styled-components';
import getMediaMangerPictureSrc from '../../utilities/getMediaMangerPictureSrc';
import __ from '../../utilities/__';
import { authUserProfileSelector } from '../App/selectors';
import { appSettingsSelector } from '../DomainResolver/selectors';

const AVATAR_SIZE = 34;

const Wrapper = styled('div')`
  display: flex;
  background-color: white;
  box-shadow: 0 0 ${rem(12)} 0 rgba(0, 0, 0, 0.3);
  height: ${rem(80)};
`;

const InnerWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
`;

const RightWrapper = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: -${rem(10)};
  & > * {
    align-items: center;
    margin-right: ${rem(10)};
  }
`;

const Menu = styled('div')`
  display: none;
  ${mb('m')} {
    display: flex;
  }
`;

const MenuItem = styled(Link)`
  text-decoration: none;
  text-align: center;
  padding: ${rem(8)} ${rem(8)};
  margin-right: ${rem(15)};
  display: block;
  cursor: pointer;
  color: inherit;
  min-width: ${rem(110)};
  &:hover {
    ${({ theme }) =>
    css`
        color: ${theme.color.primary};
        background-color: white;
      `}
  }
  &.active {
    ${({ theme }) =>
    css`
        background-color: ${theme.color.primary};
        color: ${readableColor(theme.color.primary, 'inherit', '#fff')};
      `}
  }
  transition: all 0.2s ease-out;
`;

const TextButton = styled.button`
  color: ${({ theme }) => theme.textColor};
  text-align: center;
  align-items: center;
  cursor: pointer;
  background: none;
  margin: ${rem(3)} 0 0 0;
  padding: ${rem(1)} ${rem(2)};
  font-size: ${rem(13)};
  border: none;

  display: none;
  ${mb('m')} {
    display: flex;
  }
`;

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
  userProfile: authUserProfileSelector(state),
});

type IMapStateToProps = ReturnType<typeof mapStateToProps>;

type Props = IMapStateToProps & ReduxConnectProps & RouteProps;

type Section = {
  name: string;
  _id: string;
  url: string;
};

type State = {
  menuOpened: boolean;
  sections: Section[];
};

class Header extends React.PureComponent<Props, State> {
  defaultSections = [];

  state = {
    menuOpened: false,
    sections: this.defaultSections,
  };

  handleMenuOpen = () => {
    this.setState({
      menuOpened: true,
    });
  };

  handleMenuClose = () => {
    this.setState({
      menuOpened: false,
    });
  };

  handleLinkClick = (section: Section) => {
    this.props.router.push(section.url);
    this.handleMenuClose();
  };

  renderMenu = (section: Section) => {
    const active = this.props.location.pathname.includes(section._id);
    return (
      <MenuItem
        key={section._id}
        to={section.url}
        className={active ? 'active' : ''}
      >
        {section.name}
      </MenuItem>
    );
  };

  handleLogout = () => {
    Cookies.remove(ACCESS_TOKEN_COOKIE_NAME);
    window.location.replace('/');
  };

  render() {
    const {
      appSettings: { logo, pageTitle },
      userProfile,
    } = this.props;
    const { sections } = this.state;
    return (
      <Wrapper>
        <MaxWidthBox>
          <InnerWrapper>
            {logo && (
              <Image
                alt={pageTitle}
                src={getMediaMangerPictureSrc(logo, 0, 40, 'resize')}
                height={40}
              />
            )}
            <Menu>{sections.map(this.renderMenu)}</Menu>
            <RightWrapper>
              {userProfile && (
                <CenteredContentWrapper>
                  <Avatar
                    avatarSize={AVATAR_SIZE}
                    avatarSrc={getProp(
                      userProfile,
                      ['photo', 'public_url'],
                      '',
                    )}
                    noLeftGutter
                  />
                  <TextButton onClick={this.handleLogout}>
                    {__('Odhlásiť sa')}
                  </TextButton>
                </CenteredContentWrapper>
              )}
              {/* <BurgerWrapper onClick={this.handleMenuOpen}>
                <Icon name="burger" size="xl" />
              </BurgerWrapper>
              <ResponsiveMenu
                sections={sections}
                onClose={this.handleMenuClose}
                visible={menuOpened}
                siteUrl={pageTitle}
                renderLink={({ section, linkProps }) => (
                  <Link to={section.url || ''} {...linkProps}>
                    {section.name}
                  </Link>
                )}
              /> */}
            </RightWrapper>
          </InnerWrapper>
        </MaxWidthBox>
      </Wrapper>
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(Header);
