import { reducerWithInitialState } from 'typescript-fsa-reducers';
import IAppSettings from '../../library/AppSettings';
import * as actions from './actions';

export interface IAppSettingsState {
  isFetching?: boolean;
  error?: any | null;
  data?: IAppSettings;
}

export const appSettingsReducer = reducerWithInitialState<IAppSettingsState>({})
  .case(actions.loadAppSettings.async.started, state => ({
    ...state,
    isFetching: true,
  }))
  .case(actions.loadAppSettings.async.done, (state, { result }) => ({
    ...state,
    isFetching: false,
    error: null,
    data: result,
  }))
  .case(actions.loadAppSettings.async.failed, (state, { error }) => ({
    ...state,
    isFetching: false,
    error,
  }));
