import { EntitiesState } from '../containers/App/reducer';
import { NormalizedEntities, Writeable } from '../library/App';

export default function<
  T extends keyof EntitiesState,
  E = EntitiesState[T]['']
>(type: T, arrayOfEntities: E[], id: string = '_id'): NormalizedEntities<T> {
  return {
    entities: {
      [type]: arrayOfEntities.reduce(
        (acc: Writeable<EntitiesState[T]>, item: any) => {
          acc[item[id]] = item;
          return acc;
        },
        {},
      ),
    },
    results: arrayOfEntities.map((item: any) => item[id]),
    original: arrayOfEntities,
  };
}
