import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { browserHistory } from 'react-router';
import smoothscroll from 'smoothscroll-polyfill';
import configureStore from './configureStore';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { ThemeProvider } from './theme/styled-components';
import { theme } from './theme/theme';
import { global as GlobalStyles } from './theme/globalStyles';

smoothscroll.polyfill();

let state = {};
if (typeof window !== 'undefined') {
  state = (window as any).__REDUX_STATE__;
}
const store = configureStore(state !== '{{SSR_INITIAL_STATE}}' ? state : {});

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyles />
        <Routes history={browserHistory} />
      </>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root') as HTMLElement,
);

serviceWorker.unregister();
