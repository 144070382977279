import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import Icon from '@sportnet/ui/lib/Icon';
import Message from '@sportnet/ui/lib/Message';
import Sticky from '@sportnet/ui/lib/Sticky';
import { format } from 'date-fns';
import { em, rem } from 'polished';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { compose } from 'redux';
import {
  getFormSyncErrors,
  getFormValues,
  SubmissionError,
  submit,
  updateSyncErrors,
} from 'redux-form';
import styled, { css, withTheme } from 'styled-components';
import SmartformsApi, {
  FormInstance,
  FormInstanceStatus,
} from '../../api/SmartformsApi';
import Loader from '../../components/Loader';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { appSettingsSelector } from '../../containers/DomainResolver/selectors';
import { IForm, IFormSegmentElement } from '../../library/Form';
import { IThemeProps } from '../../theme/styled-components';
import __ from '../../utilities/__';
import FormRenderer from './form';

interface OwnProps {
  formData: IForm;
  formId: string;
  initialValues: any;
  instance: FormInstance;
}

const mapStateToProps = (state: RootState, props: OwnProps & IThemeProps) => ({
  errors: props.formData ? getFormSyncErrors(props.formData._id)(state) : {},
  currentFormValues: getFormValues(props.formData._id)(state) || {},
  settings: appSettingsSelector(state),
});

type Props = RouteComponentProps<void, void> &
  OwnProps & { dispatch: CustomThunkDispatch } & ReturnType<
    typeof mapStateToProps
  > &
  IThemeProps;

const StickyWrapper = styled('div')`
  display: none;
  @media (min-width: ${em(1200)}) {
    position: relative;
    margin-left: ${em(10)};
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 25%;
    display: block;
  }
`;

const StickyInnerWrapper = styled('div')``;

const StickySegmentItem = styled('div')<{ active: boolean; error?: boolean }>`
  padding: ${em(15)};
  display: flex;
  align-items: center;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  background: ${({ active }) => (active ? '#f9f9f9' : 'white')};
  border-bottom: ${em(1)} solid #f9f9f9;
  ${({ error }) =>
    error &&
    css`
      background: ${({ theme }) => theme.color.danger};
      color: white;
    `}
  :last-child {
    border-bottom: 0;
  }
`;

const StickyLogItem = styled('div')`
  padding: ${em(15)};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  font-weight: 400;
  background: white;
  border-bottom: ${em(1)} solid #f9f9f9;
  :last-child {
    border-bottom: 0;
  }
  & > i {
    font-size: ${rem(12)};
    color: #bbb;
  }
  & > div {
    font-size: ${rem(12)};
  }
`;

const StickyItem = styled('div')`
  background: transparent;
  font-size: ${em(13)};
  box-shadow: 0px 0.0625rem 0.3125rem 0 rgba(0, 0, 0, 0.1);
  color: #555;
  font-weight: 300;
  border-radius: ${rem(5)};
  ${StickySegmentItem}:first-child, ${StickyLogItem}:first-child {
    border-top-right-radius: ${rem(5)};
    border-top-left-radius: ${rem(5)};
  }
  ${StickySegmentItem}:last-child, ${StickyLogItem}:last-child {
    border-bottom-right-radius: ${rem(5)};
    border-bottom-left-radius: ${rem(5)};
  }
`;

const StickyButtonWrapper = styled('div')`
  background: ${({ theme }) => theme.color.primary};
  border-radius: 50%;
`;

const MobileSubmitButtonWrapper = styled('div')`
  @media (min-width: ${em(1200)}) {
    display: none;
  }
  margin-top: ${rem(10)};
`;

const InstanceStatus = styled('div')<{ type: FormInstanceStatus }>`
  background: ${({ theme, type }) => {
    switch (type) {
      case 'NEW':
        return theme.color.warning;
      case 'RECEIVED':
        return theme.color.success;
      case 'PROCESSING':
        return theme.color.primary;
      case 'ACCEPTED':
        return theme.color.success;
      case 'REJECTED':
        return theme.color.danger;
      case 'STORNO':
        return theme.color.danger;
      default:
        return theme.color.primary;
    }
  }};
  border-radius: ${rem(5)};
  color: white;
  justify-content: center;
  min-height: ${rem(38)};
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-size: ${rem(14)};
  padding: 0 ${rem(22)};
`;

class Form extends React.PureComponent<Props> {
  state = {
    activeSegmentId: '',
    submitAttempted: false,
    initialValues: {},
    retransformed: false,
    submitting: false,
    submitCompleted: false,
  };

  scrollEv: null | void = null;
  stickyWrapperRef = null;

  commitInterval: number = 0;

  componentDidMount() {
    this.getActiveStickyItem();
    this.scrollEv = window.addEventListener('scroll', () => {
      this.getActiveStickyItem();
    });
    this.setState({
      initialValues: this.retransformInstanceData(this.props.initialValues),
      retransformed: true,
    });
    if (this.props.instance.status === 'DRAFT') {
      this.commitInterval = window.setInterval(() => {
        this.precommit();
      }, 10000);
    }
  }

  getActiveStickyItem = () => {
    let topSegment: null | string = null;
    const formWrapper = document.getElementById('formWrapper');
    let formHeight = 0;
    let formOffset = 0;
    let percentage = 0;
    if (formWrapper) {
      const el = formWrapper.getBoundingClientRect();
      formHeight = el.height;
      formOffset = formWrapper.offsetTop;

      const start = formHeight - window.innerHeight;
      const current = el.bottom - window.innerHeight;
      percentage = (current / start) * 100;
      if (percentage > 100) {
        percentage = 100;
      } else if (percentage < 0) {
        percentage = 0;
      }
      percentage = -(percentage - 100);
      formOffset = (window.innerHeight / 100) * percentage;
    }

    if (!topSegment) {
      this.props.formData.segments.forEach(segment => {
        if (!topSegment) {
          const segmentEl = document.getElementById(segment._id);
          if (this.isAnyPartOfElementInViewport(segmentEl, formOffset)) {
            topSegment = segment._id;
          }
        }
      });
    }
    if (!topSegment) {
      const lastSegment = [...this.props.formData.segments].pop();
      topSegment = !!lastSegment
        ? lastSegment._id
        : this.props.formData.segments[0]._id;
    }

    if (this.state.activeSegmentId !== topSegment) {
      this.setState({
        activeSegmentId: topSegment,
      });
    }
  };

  precommit = async () => {
    const {
      settings: { appSpace },
      instance,
    } = this.props;
    const currentFormValues = this.props.currentFormValues as {
      [key: string]: any;
    };
    const transformedData = Object.keys(currentFormValues)
      .reduce((acc, segmentId) => {
        const segment = this.props.formData.segments.find(
          s => s._id === segmentId,
        );
        if (segment) {
          const segmentFields = (segment.fields || []).map(
            (i: IFormSegmentElement) => {
              const id = i._id.split('.');
              let value = currentFormValues[id[0]][id[1]];
              if (i.type === 'FileUpload') {
                return { fieldId: '', value: undefined };
              } else if (i.type === 'CheckboxGroupWithInput') {
                value = Object.keys(value || {})
                  .map(k => value[k] && k)
                  .filter(k => k);
              }
              if (typeof value !== 'undefined') {
                return {
                  fieldId: i._id,
                  value,
                };
              }
              return { fieldId: '', value: undefined };
            },
          );
          return [...acc, ...segmentFields];
        }
        return acc;
      }, [])
      .filter(i => typeof i.value !== 'undefined');
    await SmartformsApi.updateFormInstance(
      appSpace,
      this.props.formId,
      instance._id,
      {},
      { values: transformedData },
    );
  };

  submit = async (data: any) => {
    const {
      settings: { appSpace },
      instance,
      formId,
    } = this.props;
    this.setState({
      submitting: true,
    });
    const currentFormValues = this.props.currentFormValues as {
      [key: string]: any;
    };
    const promises: Array<Promise<any>> = [];
    const transformedData = Object.keys(currentFormValues)
      .reduce((acc, segmentId) => {
        const segment = this.props.formData.segments.find(
          s => s._id === segmentId,
        );
        if (segment) {
          const segmentFields = (segment.fields || []).map(
            (i: IFormSegmentElement) => {
              const id = i._id.split('.');
              let value = currentFormValues[id[0]][id[1]];

              // upload suborov a kontrola ci v pripade CheckboxGroupWithInput je aspon jeden checkbox zaskrnuty ak je pole povinne
              if (i.type === 'FileUpload') {
                const filesToUpload = (value || []).reduce(
                  (filesAcc: any[], file: any) => {
                    if (file._new) {
                      filesAcc.push(file.file);
                    }
                    return filesAcc;
                  },
                  [],
                );
                if (filesToUpload.length > 0) {
                  filesToUpload.forEach((file: File) => {
                    promises.push(
                      SmartformsApi.createFormInstanceUploadAction(
                        appSpace,
                        formId,
                        instance._id,
                        {},
                        {
                          upload: file,
                        },
                      )
                        .then(res => {
                          return {
                            ...res,
                            field: i,
                          };
                        })
                        .catch(e => {
                          throw e;
                        }),
                    );
                  });
                }
                return { fieldId: '', value: undefined };
              } else if (i.type === 'CheckboxGroupWithInput') {
                const isRequired = (i.validations || []).find(
                  validation => validation.type === 'required',
                );
                const hasValue = Object.keys(value || {}).find(
                  k => value[k] === true,
                );

                if (!hasValue && isRequired) {
                  // override typu na redux form akcii pretoze typ nepodporuje hodnotu erroru ako objekt
                  const func: (
                    formId: string,
                    errors: any,
                    error: string,
                  ) => any = updateSyncErrors;

                  // naformatovanie erroru pre kazdy checkbox
                  const errors: { [key: string]: any } = {};
                  errors[id[1]] = (i.options || []).reduce(
                    (optionsAcc, option) => {
                      return {
                        ...optionsAcc,
                        [option]: __('Vyberte aspoň jednu možnosť'),
                      };
                    },
                    {},
                  );

                  this.props.dispatch(
                    func(
                      this.props.formData._id,
                      {
                        [segment._id]: errors,
                      },
                      'Error',
                    ),
                  );
                  throw new SubmissionError({});
                } else {
                  // ak je vsetko ok, prevedieme value na pole aby s tym mongo na api nemalo kvoli bodkam problem
                  value = Object.keys(value)
                    .map(k => value[k] && k)
                    .filter(k => k);
                }
              }
              if (typeof value !== 'undefined') {
                return {
                  fieldId: i._id,
                  value,
                };
              }
              return { fieldId: '', value: undefined };
            },
          );
          return [...acc, ...segmentFields];
        }
        return acc;
      }, [])
      .filter(i => typeof i.value !== 'undefined');
    const uploads = await Promise.all(promises);
    uploads.forEach(({ field, ...value }) => {
      const fieldIdx = transformedData.findIndex(
        item => item.fieldId === field._id,
      );
      if (fieldIdx > -1) {
        transformedData[fieldIdx] = {
          ...transformedData[fieldIdx],
          value: [...transformedData[fieldIdx].value, value],
        };
      } else {
        transformedData.push({
          fieldId: field._id,
          value: [value],
        });
      }
    });
    try {
      await SmartformsApi.updateFormInstance(
        appSpace,
        formId,
        instance._id,
        {},
        { values: transformedData },
      );
      await SmartformsApi.finishFormInstanceAction(
        appSpace,
        formId,
        instance._id,
      );
      window.clearInterval(this.commitInterval);
      this.props.router.replace(
        `/forms/${this.props.formId}?instanceId=${instance._id}`,
      );
    } catch (e) {
      alert(__('Uloženie sa nepodarilo.'));
    } finally {
      this.setState({
        submitting: false,
      });
    }
  };

  isAnyPartOfElementInViewport = (el: HTMLElement | null, offset: number) => {
    if (el) {
      const rect = el.getBoundingClientRect();
      const top = rect.top - offset;

      const windowHeight =
        window.innerHeight || document.documentElement.clientHeight;

      const vertInView = top <= windowHeight && top + rect.height >= 0;

      return vertInView;
    }
    return false;
  };

  retransformInstanceData = (values: any[]) =>
    values.reduce((acc, value) => {
      const split = value.fieldId.split('.');
      return {
        ...acc,
        [split[0]]: {
          ...(acc[split[0]] || {}),
          [split[1]]:
            Array.isArray(value.value) && typeof value.value[0] === 'string'
              ? value.value.reduce(
                  (valuesAcc: { [key: string]: boolean }, v: string) => {
                    return { ...valuesAcc, [v]: true };
                  },
                  {},
                )
              : value.value,
        },
      };
    }, {});

  getInstanceStatusLabel = (statusId: FormInstanceStatus) => {
    switch (statusId) {
      case 'NEW':
        return __('Čaká na prezretie');
      case 'RECEIVED':
        return __('Čaká na spracovanie');
      case 'PROCESSING':
        return __('Spracúvava sa');
      case 'ACCEPTED':
        return __('Schválené');
      case 'REJECTED':
        return __('Zamietnuté');
      case 'STORNO':
        return __('Stornované');
      default:
        return statusId;
    }
  };

  getInstanceStatus = (statusId: FormInstanceStatus) => (
    <InstanceStatus type={statusId}>
      {this.getInstanceStatusLabel(statusId)}
    </InstanceStatus>
  );

  render() {
    const {
      formData: { name },
      formData,
      theme,
      settings: { appSpace },
      formId,
      instance,
    } = this.props;
    const errors = this.props.errors as { [key: string]: any };

    if (!this.state.retransformed) {
      return <Loader />;
    }

    return (
      <>
        <Helmet>
          <title>{name}</title>
        </Helmet>
        <Header withSeparator size="m">
          {name}
        </Header>
        {this.state.submitCompleted && (
          <Message success title={__('Formulár bol odoslaný')}>
            {__('Ďakujeme, informácie boli odoslané na spracovanie.')}
          </Message>
        )}
        <div style={{ display: 'flex' }} id="formWrapper">
          <FormRenderer
            onSubmit={this.submit}
            formData={formData}
            form={formData._id}
            parameters={{
              appSpace,
              formId,
              instanceId: instance._id,
              formObjectId: instance.form._id,
            }}
            disabled={instance.status !== 'DRAFT'}
            initialValues={this.state.initialValues}
          />
          <StickyWrapper id="stickyWrapper">
            <Sticky
              offset={10}
              context={document.getElementById('stickyWrapper')}
            >
              <StickyInnerWrapper>
                <StickyItem>
                  {formData.segments.map(segment => (
                    <StickySegmentItem
                      // error={this.state.submitted && !!errors[segment._id]}
                      active={this.state.activeSegmentId === segment._id}
                      key={segment._id}
                    >
                      {this.state.submitAttempted && !!errors[segment._id] && (
                        <div style={{ paddingRight: em(10) }}>
                          <Icon color={theme.color.danger} name="error" />
                        </div>
                      )}
                      {this.state.submitAttempted && !errors[segment._id] && (
                        <div style={{ paddingRight: em(10) }}>
                          <Icon
                            color={theme.color.success}
                            name="check-circle"
                          />
                        </div>
                      )}
                      {segment.label}
                    </StickySegmentItem>
                  ))}
                </StickyItem>
              </StickyInnerWrapper>
              <div style={{ marginTop: rem(10) }}>
                <StickyInnerWrapper>
                  <StickyItem>
                    {instance.status === 'DRAFT' ? (
                      <StickyButtonWrapper>
                        <Button
                          onClick={() => {
                            this.setState({ submitAttempted: true });
                            this.props.dispatch(submit(formData._id));
                          }}
                          loading={this.state.submitting}
                          primary
                          block
                        >
                          {__('Odoslať')}
                        </Button>
                      </StickyButtonWrapper>
                    ) : (
                      <>{this.getInstanceStatus(instance.status)}</>
                    )}
                  </StickyItem>
                </StickyInnerWrapper>
              </div>
              {(instance.changelog || []).length > 0 && (
                <>
                  <div style={{ marginTop: rem(10) }}>
                    <StickyInnerWrapper>
                      <StickyItem>
                        {(instance.changelog || []).map(i => (
                          <StickyLogItem key={`${i.what}_${i.when}`}>
                            <b>
                              {this.getInstanceStatusLabel(
                                i.after as FormInstanceStatus,
                              )}
                            </b>
                            {i.comment && <div>{i.comment}</div>}
                            <i>
                              {format(new Date(i.when), 'DD.MM.YYYY o HH:mm')}
                            </i>
                          </StickyLogItem>
                        ))}
                      </StickyItem>
                    </StickyInnerWrapper>
                  </div>
                </>
              )}
            </Sticky>
          </StickyWrapper>
        </div>
        <MobileSubmitButtonWrapper>
          {instance.status === 'DRAFT' ? (
            <Button
              onClick={() => {
                this.setState({ submitAttempted: true });
                this.props.dispatch(submit(formData._id));
              }}
              loading={this.state.submitting}
              primary
              block
            >
              {__('Odoslať')}
            </Button>
          ) : (
            <>
              {this.getInstanceStatus(instance.status)}
              <div style={{ marginTop: rem(10) }}>
                <StickyInnerWrapper>
                  {(instance.changelog || []).map(i => (
                    <StickyLogItem key={`${i.what}_${i.when}`}>
                      <b>
                        {this.getInstanceStatusLabel(
                          i.after as FormInstanceStatus,
                        )}
                      </b>
                      {i.comment && <div>{i.comment}</div>}
                      <i>{format(new Date(i.when), 'DD.MM.YYYY o HH:mm')}</i>
                    </StickyLogItem>
                  ))}
                </StickyInnerWrapper>
              </div>
            </>
          )}
        </MobileSubmitButtonWrapper>
      </>
    );
  }
}

export default compose<any>(
  withTheme,
  withRouter,
  connect(mapStateToProps),
)(Form);
