import Segment from '@sportnet/ui/lib/Segment';
import SegmentHeader from '@sportnet/ui/lib/Segment/Header';
import { em } from 'polished';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, reduxForm } from 'redux-form';
import styled from 'styled-components';
import FormElementsResolver from '../../components/FormElementsResolver';
import { CustomThunkDispatch, RootState } from '../../configureStore';
import { IForm, IFormSegment } from '../../library/Form';

interface OwnProps {
  formData: IForm;
  disabled: boolean;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
    formObjectId: string;
  };
}

const mapStateToProps = (state: RootState, props: OwnProps) => ({});

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> & { dispatch: CustomThunkDispatch };

const Description = styled('div')`
  font-size: ${em(13)};
  line-height: ${em(18)};
  color: #666;
`;

class FormRenderer extends React.PureComponent<Props> {
  static scrollToFirstError = (errors: any) => {
    const segment = Object.keys(errors || {}).find(
      segmentId => typeof segmentId !== 'undefined',
    );
    if (segment) {
      // const field = (errors || {})[segment].findIndex((i: string) => i);
      const segmentDOM = document.getElementById(segment);
      if (segmentDOM) {
        window.scrollTo(0, segmentDOM.offsetTop);
      }
    }
  };

  renderSegment = (
    segment: IFormSegment,
    isLastItem: boolean,
    headerSize: 'xs' | 's',
  ) => {
    return (
      <div key={segment._id} id={segment._id}>
        <Segment
          raised
          noBottomGutter={isLastItem}
          header={
            <SegmentHeader size={headerSize}>{segment.label}</SegmentHeader>
          }
        >
          {segment.description && (
            <Segment secondary>
              <Description
                dangerouslySetInnerHTML={{ __html: segment.description }}
              />
            </Segment>
          )}
          {/* {(segment.segments || []).map((subSegment, subIdx) =>
            this.renderSegment(subSegment, false, 'xs'),
          )} */}
          <FormElementsResolver
            segment={segment}
            parameters={this.props.parameters}
            disabled={this.props.disabled}
            dispatchChange={(field: string, value: any) => {
              this.props.dispatch(
                change(this.props.formData._id, field, value),
              );
            }}
          />
        </Segment>
      </div>
    );
  };
  render() {
    const {
      formData: { segments },
    } = this.props;
    return (
      <div style={{ width: '100%' }}>
        {segments.map((segment, idx) =>
          this.renderSegment(segment, segments.length === idx + 1, 's'),
        )}
      </div>
    );
  }
}

export default compose<any, any, any>(
  reduxForm({
    onSubmitFail: errors => FormRenderer.scrollToFirstError(errors),
    enableReinitialize: true,
  }),
  connect(mapStateToProps),
)(FormRenderer);
