import React from 'react';
import { connect } from 'react-redux';
import { RouteProps } from 'react-router';
import { ReduxConnectProps, RootState } from '../../configureStore';
import IAppSettings from '../../library/AppSettings';
import getDomain from '../../utilities/getDomain';
import getPageOrigin from '../../utilities/getPageOrigin';
import Loading from '../Loading';
import NotFound from '../NotFound';
import { loadAppSettings } from './actions';
import {
  appSettingsDidFailSelector,
  appSettingsIsFetchingSelector,
  appSettingsSelector,
} from './selectors';

const manifestTemplate = JSON.stringify({
  name: '{{NAME}}',
  short_name: '{{SHORT_NAME}}', // max 12 characters
  icons: [
    {
      src: '{{ORIGIN}}/android-chrome-192x192.png',
      sizes: '192x192',
      type: 'image/png',
    },
    {
      src: '{{ORIGIN}}/android-chrome-512x512.png',
      sizes: '512x512',
      type: 'image/png',
    },
  ],
  start_url: '{{ORIGIN}}',
  theme_color: '#000',
  background_color: '#ffffff',
  display: 'standalone',
});

type OwnProps = { hostname?: string };

const mapStateToProps = (state: RootState) => {
  return {
    appSettings: appSettingsSelector(state),
    isFetching: appSettingsIsFetchingSelector(state),
    didFail: appSettingsDidFailSelector(state),
  };
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  ReduxConnectProps &
  RouteProps<{}>;

class DomainResolver extends React.PureComponent<Props> {
  static async getInitialProps(props: Props) {
    const { dispatch } = props;
    const domain = getDomain(props);
    return dispatch(loadAppSettings.action({ domain }));
  }

  async componentDidMount() {
    try {
      const appSettings = await DomainResolver.getInitialProps(this.props);
      this.createCustomManifest(appSettings);
    } catch (e) {
      /* */
    }
  }

  createCustomManifest = (appSettings: IAppSettings) => {
    if (typeof document !== 'undefined') {
      const { pageTitle } = appSettings;
      const stringManifest = manifestTemplate
        .replace(/{{NAME}}/g, pageTitle)
        .replace(/{{SHORT_NAME}}/g, pageTitle)
        .replace(/{{ORIGIN}}/g, getPageOrigin());
      const blob = new Blob([stringManifest], { type: 'application/json' });
      const manifestURL = URL.createObjectURL(blob);
      (document.querySelector(
        '#manifest',
      ) as HTMLLinkElement).href = manifestURL;
    }
  };

  render() {
    const { appSettings, isFetching, didFail, children } = this.props;

    const areSettingsLoaded = Object.keys(appSettings).length > 0;

    if (isFetching && !areSettingsLoaded) {
      return <Loading />;
    }

    if (!areSettingsLoaded || didFail) {
      return <NotFound />;
    }

    return children;
  }
}

export default connect(mapStateToProps)(DomainResolver);
