import { rem } from 'polished';
import React from 'react';
import styled from '../../theme/styled-components';

const Wrapper = styled('div')<{ elMaxWidth?: number }>`
  padding: 0 ${({ theme }) => rem(theme.app.padding)};
  max-width: ${({ elMaxWidth, theme }) =>
    rem((elMaxWidth || theme.app.maxWidth) + theme.app.padding * 2)};
  margin: 0 auto;
  width: 100%;
  flex: 1 0 0;
`;

const MaxWidthBox: React.FC<{ width?: number; className?: string }> = ({
  children,
  width,
  className,
}) => {
  return (
    <Wrapper className={className} elMaxWidth={width}>
      {children}
    </Wrapper>
  );
};

export default MaxWidthBox;
