import { IFormSegmentElementValidation } from '../library/Form';
import maxLength from './maxLength';
import minLength from './minLength';
import required from './required';

export default (validationTypes: IFormSegmentElementValidation[]) => {
  const validations: Array<(value: string | number) => string | null> = [];
  validationTypes.forEach(validation => {
    if (validation.type === 'required') {
      validations.push(required);
    } else if (validation.type === 'minLength') {
      validations.push(
        minLength(
          validation.payload && validation.payload.minLength
            ? validation.payload.minLength
            : 0,
        ),
      );
    } else if (validation.type === 'maxLength') {
      validations.push(
        maxLength(
          validation.payload && validation.payload.maxLength
            ? validation.payload.maxLength
            : 0,
        ),
      );
    }
  });
  return validations;
};
