import LoaderDefault from '@sportnet/ui/lib/Loader';
import React from 'react';
import { Helmet } from 'react-helmet';
import EntryAnimation from '../../components/EntryAnimation';
import { CenteredContentWrapper, PageWrapper } from '../../components/Layout';
import __ from '../../utilities/__';
import Footer from '../Footer';

const Loading: React.FC<{}> = () => {
  return (
    <PageWrapper>
      <Helmet>
        <title>{__('Načítavam obsah')}</title>
      </Helmet>
      <CenteredContentWrapper>
        <EntryAnimation key={'loading'}>
          <LoaderDefault size="xl" />
        </EntryAnimation>
      </CenteredContentWrapper>
      <Footer />
    </PageWrapper>
  );
};

export default Loading;
