import Button from '@sportnet/ui/lib/Button';
import { Loader } from '@sportnet/ui/lib/Loader';
import { Theme } from '@sportnet/ui/lib/Themes/baseTheme';
import { rem } from 'polished';
import * as React from 'react';
import styled, { css, withTheme } from 'styled-components';
import { FileObjectDefinition } from './definitions';

const Title = styled.div`
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Name = styled(Title)`
  font-size: ${rem(14)};
  display: flex;
`;

const ButtoWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const Wrapper = styled('div')<{ clickable?: boolean; isNew?: boolean }>`
  position: relative;
  padding: ${rem(8)} ${rem(10)};
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `};
  ${({ isNew }) =>
    isNew &&
    css`
      background: #ebf1ff;
    `}
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily};
  .sportnetui-btn {
    padding: 0 ${rem(7)};
    font-size: ${rem(8)};
    min-height: ${rem(22)};
    .icon {
      height: ${rem(8)};
      width: ${rem(8)};
    }
  }
  border-bottom: ${rem(1)} solid ${({ theme }) => theme.separatorColor};
  min-height: ${rem(45)};
  align-items: center;
`;

interface OwnProps {
  file: FileObjectDefinition;
  onDelete: (e: any) => void;
  onClick?: () => void;
  disabled: boolean;
}

type Props = OwnProps & { theme: Theme };

class FileLine extends React.PureComponent<Props> {
  state = {
    isFetching: false,
  };
  render() {
    const { file, onDelete, onClick, theme } = this.props;
    return (
      <Wrapper
        isNew={!file.fileid}
        onClick={async () => {
          if (onClick) {
            this.setState({
              isFetching: true,
            });
            await onClick();
            this.setState({
              isFetching: false,
            });
          }
        }}
        clickable={!!onClick}
      >
        <Name>
          {file.name || ''}{' '}
          {this.state.isFetching && (
            <div style={{ paddingLeft: '20px' }}>
              <Loader theme={theme} size="xs" />
            </div>
          )}
        </Name>
        {!file.fileid && !this.props.disabled && (
          <ButtoWrapper>
            <Button danger icon="close" onClick={onDelete} />
          </ButtoWrapper>
        )}
      </Wrapper>
    );
  }
}

export default withTheme(FileLine);
