import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { listReducer } from 'redux-list/lib/ducks';
import {
  authUserReducer,
  entitiesReducer,
  IAuthUserState,
} from './containers/App/reducer';
import {
  appSettingsReducer,
  IAppSettingsState,
} from './containers/DomainResolver/reducer';

const rootReducer = combineReducers({
  form: formReducer,
  application: combineReducers<{
    settings: IAppSettingsState;
    authUser: IAuthUserState;
  }>({
    settings: appSettingsReducer,
    authUser: authUserReducer,
  }),
  entities: entitiesReducer,
  list: listReducer,
});

export default rootReducer;
