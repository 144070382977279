import { createGlobalStyle } from 'styled-components';

export const global = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Roboto:400,400i,500,700&display=swap&subset=latin-ext');
  html, body, #root {
    margin: 0;
    padding: 0;
    min-height: 100%;
    width: 100%;
    scroll-behavior: smooth;
  }
  html {
    font-size: 100%;
    font-family: 'Roboto', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  a {
    color: inherit;
    text-decoration: none;
  }
`;
