import React from 'react';
import { Redirect, Route, Router } from 'react-router';
import App from './containers/App';
import Authorization from './containers/Authorization';
import DomainResolver from './containers/DomainResolver';
import FormResolver from './containers/FormResolver';
import NotFound from './containers/NotFound';
import Themer from './containers/Themer';

// TODO: check if redirect works during SSR
const Routes: React.SFC<any> = props => {
  return (
    <Router {...props}>
      <Route component={DomainResolver}>
        <Route component={Themer}>
          <Route component={App}>
            <Route component={Authorization}>
              <Route path="/forms/:formId" component={FormResolver} />
              {
                // docasny redirect na jediny existujuci formular
              }
              <Redirect
                from="/"
                to="/forms/zavazna-ziadost-o-poskytnutie-financneho-prispevku"
              />
              <Route path="*" component={NotFound} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Router>
  );
};

export default Routes;
