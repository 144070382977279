import { rem } from 'polished';
import React from 'react';
import SportnetLogoLight from '../../assets/sportnet-logo-light.svg';
import { FlexContainer } from '../../components/Layout';
import MaxWidthBox from '../../components/MaxWidthBox';
import styled from '../../theme/styled-components';
import __ from '../../utilities/__';

const Wrapper = styled('div')`
  display: flex;
  background-color: white;
  height: ${rem(80)};
  align-items: center;
  margin-top: auto;
`;

const Logo = styled('img')`
  height: ${rem(40)};
  margin-left: auto;
  display: block;
`;

type Props = {};

const Footer: React.FC<Props> = () => {
  return (
    <Wrapper>
      <MaxWidthBox>
        <FlexContainer>
          <Logo alt={__('Logo Sportnet online')} src={SportnetLogoLight} />
        </FlexContainer>
      </MaxWidthBox>
    </Wrapper>
  );
};

export default Footer;
