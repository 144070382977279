import FormGroup from '@sportnet/ui/lib/FormGroup';
import Label from '@sportnet/ui/lib/Label/Label';
import * as React from 'react';
import { Field } from 'redux-form';
import SmartformsApi, { FormInstanceUpload } from '../../../api/SmartformsApi';
import { IFormSegmentElement } from '../../../library/Form';
import getValidationFunctions from '../../../utilities/getValidationFunctions';
import __ from '../../../utilities/__';
import FileInput from '../../FileInput/redux-form';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  disabled: boolean;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
  };
}

const COMPONENT_VALIDATIONS: Array<(value: string) => string | null> = [];

class FileUploadFormElement extends React.PureComponent<OwnProps> {
  downloadFile = async (file: FormInstanceUpload) => {
    const {
      parameters: { appSpace, formId, instanceId },
    } = this.props;
    try {
      const res = await SmartformsApi.adminGetFormInstanceUploadDownloader(
        appSpace,
        formId,
        instanceId,
        file._id,
      );
      window.open(res.url, '_blank');
    } catch (e) {
      alert(__('Súbor nie je možné stiahnuť'));
    }
  };

  render() {
    const { data, name, disabled } = this.props;
    const isRequired = (data.validations || []).find(
      validation => validation.type === 'required',
    );

    return (
      <FormGroup>
        {data.label && (
          <Label
            {...(!!isRequired && {
              required: true,
            })}
          >
            {data.label}
          </Label>
        )}
        <div>
          <Field
            {...(!!isRequired && {
              required: true,
            })}
            id={name}
            disabled={disabled}
            validate={[
              ...COMPONENT_VALIDATIONS,
              ...getValidationFunctions(data.validations || []),
            ]}
            name={name}
            component={FileInput}
            multiple
            {...(disabled && { onFileClick: this.downloadFile })}
          />
        </div>
      </FormGroup>
    );
  }
}

export default FileUploadFormElement;
