import {
  getListParameters,
  initialize,
  ListParameters,
  setParams,
} from 'redux-list/lib/ducks';
import actionCreatorFactory from 'typescript-fsa';
import { asyncFactory } from 'typescript-fsa-redux-thunk';
import { ExtraArgumentType, RootState } from '../../configureStore';
import {
  IAuthorizationResponse,
  IUser,
  NormalizedEntities,
} from '../../library/App';
import normalizeEntities from '../../utilities/normalizeEntities';

const create = actionCreatorFactory('APP');
const createAsync = asyncFactory<RootState, ExtraArgumentType>(create);

export const setAccessToken = create<string>('SET_ACCESS_TOKEN');
export const setAuthUser = create<IAuthorizationResponse>('SET_AUTH_USER');

export const initializeOrSetListParams = createAsync<
  {
    listName: string;
    params: ListParameters;
  },
  any
>('INITIALIZE_OR_SET_LIST_PARAMS', async (parameters, dispatch, getState) => {
  const reduxListParams = getListParameters(parameters.listName)(getState());
  if (Object.keys(reduxListParams).length === 0) {
    return dispatch(
      initialize({
        listName: parameters.listName,
        initialParams: parameters.params,
      }),
    );
  }
  return dispatch(
    setParams({
      listName: parameters.listName,
      parameters: parameters.params,
    }),
  );
});

export const loadCodelist = createAsync<
  {
    codelist: string;
  },
  NormalizedEntities<'codelists'>
>('GET_CODELIST', async ({ codelist }, dispatch, getState, { CoreApi }) => {
  const response = await CoreApi.getCodelist(codelist);
  return normalizeEntities('codelists', [
    { _id: codelist, items: response.codelist },
  ]);
});

export const loadAuthUserAddresses = createAsync<void, IUser['addresses']>(
  'GET_AUTH_USER_ADDRESSES',
  async (parameters, dispatch, getState, { CoreApi }) => {
    const { items: addresses } = await CoreApi.meAddresses();
    return addresses!;
  },
);
