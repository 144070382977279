import { getMediaManagerUrl } from '@sportnet/ui/lib/MediaManagerImage';

export default (
  picture: object | string | null | undefined,
  width: number,
  height: number,
  script?: 'resize' | 'media' | undefined,
) => {
  if (!picture) {
    return '';
  }
  let src = '';
  if (typeof picture === 'string') {
    src = picture;
  } else if (typeof picture === 'object') {
    src = getMediaManagerUrl(picture, width, height, script);
  }
  return src;
};
