import * as React from 'react';
import { getProp } from 'sportnet-utilities';
import styled, { css } from 'styled-components';

const Wrapper = styled('div')<{ hovering?: number }>`
  position: relative;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  transition: background-color 0.3s ease-in-out;
  ${({ hovering }) =>
    hovering &&
    css`
      background-color: ${({ theme }) => theme.color.successBg};
    `};
`;

interface OwnProps {
  onDropFiles: (files: FileList | File[] | null) => void;
}

type Props = OwnProps & {};

class Dropzone extends React.PureComponent<Props> {
  state = {
    hovering: 0, // this is number because of how onDragEnter and onDragLeave works
  };

  handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ hovering: this.state.hovering + 1 });
  };

  handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.setState({ hovering: this.state.hovering - 1 });
  };

  handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ hovering: 0 });

    const droppedFiles = e.dataTransfer
      ? e.dataTransfer.files
      : (getProp(e, ['target', 'files'], []) as FileList);
    const files: File[] = [];
    Array.from(droppedFiles).forEach(file => {
      if (file.type) {
        files.push(file);
      }
    });

    this.props.onDropFiles(files);
  };

  render() {
    const { children } = this.props;
    const { hovering } = this.state;
    return (
      <Wrapper
        onDragEnter={this.handleDragEnter}
        onDragOver={this.handleDragOver}
        onDragLeave={this.handleDragLeave}
        onDrop={this.handleDrop}
        hovering={hovering}
      >
        {children}
      </Wrapper>
    );
  }
}

export default Dropzone;
