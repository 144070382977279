import Col, { Row } from '@sportnet/ui/lib/Grid';
import { Loader } from '@sportnet/ui/lib/Loader';
import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { getFormValues } from 'redux-form';
import { formatUserName, getProp } from 'sportnet-utilities';
import styled, { withTheme } from 'styled-components';
import CoreApi from '../../../api/CoreApi';
import { RootState } from '../../../configureStore';
import { IFormSegmentElement } from '../../../library/Form';
import { IThemeProps } from '../../../theme/styled-components';
import __ from '../../../utilities/__';
import EmailInputFormElement from '../../FormElements/EmailInputFormElement';
import TextInputFormElement from '../../FormElements/TextInputFormElement';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  dispatchChange: (field: string, value: any) => void;
  disabled: boolean;
  parameters: {
    appSpace: string;
    formId: string;
    instanceId: string;
    formObjectId: string;
  };
}

const Wrapper = styled('div')`
  position: relative;
`;

const LoaderWrapper = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const mapStateToProps = (state: RootState, props: OwnProps) => {
  return {
    currentFormValues: getFormValues(props.parameters.formObjectId)(state),
  };
};

type Props = OwnProps & IThemeProps & ReturnType<typeof mapStateToProps>;

class PersonGroupFormElement extends React.PureComponent<Props> {
  state = {
    personIsFetching: false,
  };

  componentWillReceiveProps(nextProps: Props) {
    if (!this.props.currentFormValues && nextProps.currentFormValues) {
      this.getMe(nextProps);
    }
  }

  getMe = async (props = this.props) => {
    const currentValues: { [key: string]: any } = props.currentFormValues;
    const keys = props.name.split('.');

    if (!getProp(currentValues, keys)) {
      this.setState({
        personIsFetching: true,
      });
      try {
        const res = await CoreApi.me();
        this.props.dispatchChange(this.props.name, {
          contactPersonName: `${formatUserName(res)}`,
          contactPersonEmail: res.email,
          contactPersonPhone: res.phone,
        });
      } catch (e) {
        //
      } finally {
        this.setState({
          personIsFetching: false,
        });
      }
    }
  };

  render() {
    const { name, theme, disabled } = this.props;
    return (
      <Wrapper>
        {this.state.personIsFetching && (
          <LoaderWrapper>
            <Loader size="xl" theme={theme} />
          </LoaderWrapper>
        )}
        <Row>
          <Col xs={12}>
            <TextInputFormElement
              name={`${name}.contactPersonName`}
              disabled={disabled}
              data={{
                _id: 'contactPersonName',
                label: __('Meno a priezvisko'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={6}>
            <TextInputFormElement
              disabled={disabled}
              name={`${name}.contactPersonPhone`}
              data={{
                _id: 'contactPersonPhone',
                label: __('Telefónne číslo'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
          <Col xs={6}>
            <EmailInputFormElement
              disabled={disabled}
              name={`${name}.contactPersonEmail`}
              data={{
                _id: 'contactPersonEmail',
                label: __('Email'),
                validations: [{ type: 'required' }],
                type: 'TextInput',
              }}
            />
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default compose(
  withTheme,
  connect(mapStateToProps),
)(PersonGroupFormElement);
