import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import SmartformsApi from '../../api/SmartformsApi';
import Loader from '../../components/Loader';
import { RootState } from '../../configureStore';
import { RouteProps } from '../../library/App';
import Form from '../../pages/Form';
import { appSettingsSelector } from '../DomainResolver/selectors';
import NotFound from '../NotFound';

const mapStateToProps = (state: RootState) => ({
  settings: appSettingsSelector(state),
});

type Props = RouteProps<{ formId: string }> &
  ReturnType<typeof mapStateToProps>;

class FormResolver extends React.PureComponent<Props> {
  state = {
    isFetching: false,
    form: null,
    initialValues: null,
    instance: null,
    commitFailed: false,
  };
  componentDidMount() {
    if (this.props.location.query && this.props.location.query.instanceId) {
      this.loadInstanceData(
        this.props.params.formId,
        String(this.props.location.query.instanceId),
      );
    } else {
      this.getFormData(this.props.params.formId);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      prevProps.location.query.instanceId !==
      this.props.location.query.instanceId
    ) {
      this.loadInstanceData(
        this.props.params.formId,
        String(this.props.location.query.instanceId),
      );
    }
  }

  getFormData = async (formId: string) => {
    const {
      settings: { appSpace },
    } = this.props;
    this.setState({
      isFetching: true,
    });
    try {
      const formData = await SmartformsApi.publicGetForm(appSpace, formId);
      const {
        values,
        ...instance
      } = await SmartformsApi.getOrCreateFormInstance(appSpace, formId);
      this.setState({
        form: formData,
        initialValues: values,
        instance,
      });
    } catch (e) {
      this.setState({
        commitFailed: true,
      });
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  loadInstanceData = async (formId: string, instanceId: string) => {
    const {
      settings: { appSpace },
    } = this.props;
    this.setState({
      isFetching: true,
    });
    try {
      const formData = await SmartformsApi.publicGetForm(appSpace, formId);
      const { values, ...instance } = await SmartformsApi.getFormInstance(
        appSpace,
        formId,
        instanceId,
      );
      this.setState({
        form: formData,
        initialValues: values,
        instance,
      });
    } catch (e) {
      this.setState({
        commitFailed: true,
      });
    } finally {
      this.setState({
        isFetching: false,
      });
    }
  };

  render() {
    const { form, initialValues, instance } = this.state;
    if (this.state.commitFailed) {
      return <NotFound />;
    }
    if (this.state.isFetching || !form) {
      return <Loader />;
    }
    return (
      <Form
        instance={instance}
        formId={this.props.params.formId}
        formData={form}
        initialValues={initialValues}
      />
    );
  }
}

export default compose(
  withRouter,
  connect(mapStateToProps),
)(FormResolver);
