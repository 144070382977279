import React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { useAsyncData } from 'ssr-service';
import { thunkToAction } from 'typescript-fsa-redux-thunk';
import { FlexContainer, MainContentWrapper, PageWrapper } from '../../components/Layout';
import MaxWidthBox from '../../components/MaxWidthBox';
import { RootState } from '../../configureStore';
import getMediaMangerPictureSrc from '../../utilities/getMediaMangerPictureSrc';
import { appSettingsSelector } from '../DomainResolver/selectors';
import Footer from '../Footer';
import Header from '../Header';
import { loadCodelist } from './actions';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

const mapDispatchToProps = {
  getCodelist: thunkToAction(loadCodelist.action),
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<void, void>;

const App: React.FC<Props> = ({
  children,
  location: { pathname },
  appSettings,
  getCodelist,
}) => {
  useAsyncData(async () => {
    await getCodelist({ codelist: 'country' });
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined') {
      window.scroll({ left: 0, top: 0, behavior: 'smooth' });
    }
  }, [pathname]);
  const { baseUri, pageTitle, favicon, logo } = appSettings;
  const logoSrc = getMediaMangerPictureSrc(logo, 1000, 0, 'resize');
  const faviconSrc = getMediaMangerPictureSrc(favicon, 32, 32, 'media');
  return (
    <PageWrapper>
      <Helmet titleTemplate={`%s | ${pageTitle}`}>
        {faviconSrc && <link rel="shortcut icon" href={faviconSrc} />}
        <meta property="og:url" content={`${baseUri}`} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="website" />
        {logoSrc && <meta property="og:image" content={logoSrc} />}
        {/* <meta name="description" content="" /> */}
        {/* <meta name="twitter:description" content=""> */}
        <meta name="twitter:title" content={pageTitle} />
        {logoSrc && <meta name="twitter:image" content={logoSrc} />}
        <meta name="twitter:image:alt" content={pageTitle} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Header />
      <FlexContainer>
        <MaxWidthBox>
          <MainContentWrapper>{children}</MainContentWrapper>
        </MaxWidthBox>
      </FlexContainer>
      <Footer />
    </PageWrapper>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(App);
