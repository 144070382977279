import AuthorizationComponent from '@sportnet/ui/lib/Authorization';
import React, { memo, useCallback } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import CoreApi from '../../api/CoreApi';
import SmartformsApi from '../../api/SmartformsApi';
import config from '../../config';
import { ReduxConnectProps, RootState } from '../../configureStore';
import { IAuthorizationResponse, RouteProps } from '../../library/App';
import { setAuthUser } from '../App/actions';
import { appSettingsSelector } from '../DomainResolver/selectors';

type OwnProps = {};

const mapStateToProps = (state: RootState) => ({
  appSettings: appSettingsSelector(state),
});

const mapDispatchToProps = {
  setAuthResponse: setAuthUser,
};

type Props = OwnProps &
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  ReduxConnectProps &
  RouteProps;

const Authorization: React.FC<Props> = ({
  setAuthResponse,
  children,
  location: { pathname },
  appSettings: { appSpace },
}) => {
  const handleAuthorized = useCallback(
    (response: IAuthorizationResponse) => {
      setAuthResponse(response);
      CoreApi.setToken(response.accessToken);
      SmartformsApi.setToken(response.accessToken);
    },
    [setAuthResponse],
  );

  const handleLogout = useCallback(() => {
    window.location.href = '/';
  }, []);

  return (
    <AuthorizationComponent
      appId={config.AUTH_CLIENT_ID}
      onAuthorized={handleAuthorized}
      scope="profile,groups,messages,profile_picture"
      basePath={pathname}
      onAfterLogout={handleLogout}
      appSpaceId={appSpace}
    >
      {children}
    </AuthorizationComponent>
  );
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  memo,
)(Authorization);
