import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { IFormSegmentElement } from '../../../library/Form';
import getValidationFunctions from '../../../utilities/getValidationFunctions';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  disabled: boolean;
}

const COMPONENT_VALIDATIONS: Array<(value: string) => string | null> = [];

class SelectFormElement extends React.PureComponent<OwnProps> {
  render() {
    const { data, name, disabled } = this.props;
    const isRequired = (data.validations || []).find(
      validation => validation.type === 'required',
    );

    return (
      <Field
        {...(!!isRequired && {
          required: true,
        })}
        disabled={disabled}
        validate={[
          ...COMPONENT_VALIDATIONS,
          ...getValidationFunctions(data.validations || []),
        ]}
        name={name}
        type="theselect"
        parse={(item: any) => {
          if (!item) {
            return '';
          }
          return item.value;
        }}
        format={(i: any) => {
          if (i) {
            const option = (data.options || []).find(o => o === i);
            if (option) {
              return {
                value: i,
                label: i,
              };
            }
          }
          return '';
        }}
        options={(data.options || []).map(option => ({
          label: option,
          value: option,
        }))}
        component={FormField}
        label={data.label}
      />
    );
  }
}

export default SelectFormElement;
