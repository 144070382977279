import FormField from '@sportnet/ui/lib/FormField/redux-form';
import * as React from 'react';
import { Field } from 'redux-form';
import { IFormSegmentElement } from '../../../library/Form';
import getValidationFunctions from '../../../utilities/getValidationFunctions';

interface OwnProps {
  data: IFormSegmentElement;
  name: string;
  disabled: boolean;
}

const COMPONENT_VALIDATIONS: Array<(value: string) => string | null> = [];

class TextareaFormElement extends React.PureComponent<OwnProps> {
  render() {
    const { data, name, disabled } = this.props;
    const isRequired = (data.validations || []).find(
      validation => validation.type === 'required',
    );

    return (
      <Field
        {...(!!isRequired && {
          required: true,
        })}
        readOnly={disabled}
        validate={[
          ...COMPONENT_VALIDATIONS,
          ...getValidationFunctions(data.validations || []),
        ]}
        name={name}
        type="textarea"
        component={FormField}
        label={data.label}
      />
    );
  }
}

export default TextareaFormElement;
