import Button from '@sportnet/ui/lib/Button';
import Header from '@sportnet/ui/lib/Header';
import { rem } from 'polished';
import styled from '../../theme/styled-components';

export const PageWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.app.bgColor};
`;

export const CenteredContentWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const MainContentWrapper = styled('main')`
  display: flex;
  flex-direction: column;
  margin: ${rem(20)} 0;
`;

export const FlexContainer = styled.div`
  display: flex;
`;

export const RightContentWrapper = styled.div`
  display: flex;
  justify-content: end;
`;

export const Title = Header;

export const RightButton = styled(Button)`
  margin-left: auto;
  display: flex;
`;
