import { createSelector } from 'reselect';
import IAppSettings from '../../library/AppSettings';
import { IAppSettingsState } from './reducer';

const appSettingsDomainSelector = (state: any) =>
  state.application.settings as IAppSettingsState;

export const appSettingsIsFetchingSelector = createSelector(
  appSettingsDomainSelector,
  domain => !!domain.isFetching,
);

export const appSettingsDidFailSelector = createSelector(
  appSettingsDomainSelector,
  domain => !!domain.error,
);

export const appSettingsSelector = createSelector(
  appSettingsDomainSelector,
  domain =>
    ({
      ...(domain.data || {}),
      pageTitle: 'SFZ',
      favicon: null,
      logo:
        'https://api.sportnet.online/data/ppo/futbalsfz.sk/563b3011-59e3-4f04-8055-e5713ba17560.png',
    } as IAppSettings), // AppSettings present in every component (except for DomainResolver)
);
