import { ISingleValue } from '@sportnet/ui/lib/TheSelect/types';
import { createSelector } from 'reselect';
import { getProp } from 'sportnet-utilities';
import { RootState } from '../../configureStore';
import {
  CodelistItems,
  CodelistLabelByValue,
  IAddress,
  ICodelistItemsByValue,
  IUser,
} from '../../library/App';
import { appSettingsSelector } from '../DomainResolver/selectors';
import { EntitiesState } from './reducer';

// Entities
export const entitiesSelector = (state: RootState) => {
  return state.entities;
};

export const currentPPOSelector = createSelector(
  entitiesSelector,
  appSettingsSelector,
  (entities, { appSpace }) => {
    return entities.PPO[appSpace];
  },
);

export const entityTypeSelector = <T extends keyof EntitiesState>(type: T) =>
  createSelector(
    entitiesSelector,
    () => type,
    (entities, entityType) => entities[entityType],
  );

export const entitySelector = <T extends keyof EntitiesState, I extends string>(
  type: T,
  id: I,
) =>
  createSelector(
    entityTypeSelector(type),
    () => id,
    (entityById, entityId) =>
      entityId in entityById ? entityById[entityId] : null,
  );

// Codelist

export const codelistItemsSelector = (id: string) =>
  createSelector(
    entitySelector('codelists', id),
    codelist =>
      (getProp(codelist, ['items'], []) as CodelistItems).map(
        ({ label, value }) => ({
          value,
          label: label || value,
        }),
      ),
  );

export const codelistItemsByValueSelector = (id: string) =>
  createSelector(
    codelistItemsSelector(id),
    codelistItems => {
      return codelistItems.reduce(
        (acc, codelistItem) => {
          acc[codelistItem.value] = codelistItem;
          return acc;
        },
        {} as ICodelistItemsByValue,
      );
    },
  );

export const codelistLabelByValueSelector = (id: string) =>
  createSelector(
    codelistItemsSelector(id),
    codelistItems => {
      return codelistItems.reduce((acc: CodelistLabelByValue, codelistItem) => {
        acc[codelistItem.value] = codelistItem.label || codelistItem.value;
        return acc;
      }, {});
    },
  );

const applicationDomainSelector = (state: RootState) => state.application;

const authUserDomainSelector = createSelector(
  applicationDomainSelector,
  domain => domain.authUser,
);

export const accessTokenSelector = createSelector(
  authUserDomainSelector,
  domain => domain.accessToken,
);

export const authUserProfileSelector = createSelector(
  authUserDomainSelector,
  domain => domain.profile,
);

export const isUserSignedInSelector = createSelector(
  authUserDomainSelector,
  domain => !!domain.profile,
);

export const authUserInvoiceAddressSelector = createSelector(
  authUserProfileSelector,
  profile => {
    const addresses = getProp(profile, ['addresses'], []) as NonNullable<
      IUser['addresses']
    >;
    return addresses.find(a => a.type === 'INVOICE') || null;
  },
);

const formatAddressLabel = (address: IAddress) => {
  let label = '';
  if (address.street) {
    label += address.street;
    if (address.number) {
      label += ` ${address.number}`;
    }
  }
  if (address.city) {
    label += `, ${address.city}`;
  }
  return label;
};

export const authUserAddressesCodelistSelector = createSelector(
  authUserProfileSelector,
  profile => {
    const addresses = getProp(profile, ['addresses'], []) as IAddress[];
    return addresses.map(address => ({
      ...address,
      label: formatAddressLabel(address),
      value: address._id!,
    })) as Array<IAddress & ISingleValue>;
  },
);
